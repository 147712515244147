// Here you can add other styles
@import url("~leaflet/dist/leaflet.css");

$base-color: #22ac11;
$blue-color: #e67e22;
$blue-color1: #dc3545;
$text-color: black;
$text-color3: #007bff;

/*Legend specific*/
.legend {
  padding: 6px 8px;
  font: 14px Arial, Helvetica, sans-serif;
  background: white;
  background: rgba(255, 255, 255, 0.8);
  /*box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);*/
  /*border-radius: 5px;*/
  line-height: 24px;
  color: #555;
}
.legend h4 {
  text-align: center;
  font-size: 16px;
  margin: 2px 12px 8px;
  color: #777;
}

.legend span {
  position: relative;
  bottom: 3px;
}

.legend i {
  width: 18px;
  height: 18px;
  float: left;
  margin: 0 8px 0 0;
  opacity: 0.7;
}

.legend i.icon {
  background-size: 18px;
  background-color: rgba(255, 255, 255, 1);
}

.cardColorBlue {
  color: #007bff !important;
}

.icon-Disponible {
  color: #22ac11 !important;
}

.icon-NoDisponible {
  color: #dc3545 !important;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  border-bottom: 5px solid #007bff;
  background: #e1f4ff;
  font-size: 1.2rem !important;
  color: #007bff !important;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link {
  font-size: 1.2rem !important;
  color: #9a9a9a !important;
}

.loading {
  font-size: 1.2rem !important;
  color: #dc3545 !important;
  text-align: center !important;
}

.MuiTableCell-alignLeft {
  text-align: left !important;
}

.CIconColor {
  color: $text-color3;
}

.text-rojo {
  color: $blue-color1;
}

.logo-empresaBpsystem {
  width: 70%;
}

.img_carrucelPerfil {
  width: 100% !important;
}

.img_carrucel {
  width: 100% !important;
  height: 100% !important;
}

.textoMenu {
  font-size: 1rem !important;
}

.textoMenuOFF {
  font-size: 1rem !important;
  color: red !important;
}

.textoTabla {
  font-size: 0.8rem !important;
}

.textoOption {
  font-size: 0.8rem !important;
}

.textoTurno {
  font-size: 1rem !important;
}
// ------------ sidebar --------------------------------------------------------------
.sidebar-nav .nav-icon {
  flex: 0 0 3rem !important;
  height: 2rem !important;

  color: rgba(255, 255, 255, 0.6);
  color: var(--cui-sidebar-nav-link-icon-color, rgba(255, 255, 255, 0.6));
  text-align: center;
  fill: currentColor;
  transition: inherit;
}

.nav-icon {
  width: 30px !important;
  height: 30px !important;
}

.sidebar {
  --cui-sidebar-width: 17rem !important;
}
// ------------ sidebar --------------------------------------------------------------

.Row-leaflet-containerDenGrab {
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid #eeeeee !important;
  background-color: #fff !important;
  border-radius: 5px !important;
  box-shadow: 0 2px 3px rgba(212, 207, 207, 20%),
    0 3px 3px rgba(212, 207, 207, 20%) !important;
  margin-bottom: 10px !important;
  height: 60vh !important;
}

//------------Login----------------------------------------------------------------------------

.col-titulo-login {
  border: 1px solid #eeeeee !important;
  background-color: #fff !important;
  border-radius: 5px !important;
  box-shadow: 0 2px 3px #d4cfcf, 0 3px 3px #d4cfcf !important;
  margin-bottom: 10px !important;
  padding: 10px !important;
  margin-top: 3%;
  margin-right: auto;
  margin-left: auto;
  height: 100%;
  width: 30vw;
  min-height: 2.5rem;
  min-width: 2.5rem;
}

.um_data-login-row {
  padding: 10px !important;
  margin-top: 10px !important;
}

.btn {
  margin-top: 10px !important;
  padding-left: 1.22rem;
  padding-right: 1.22rem;
}

.btn1 {
  margin-top: 0px !important;
  padding-left: 1.22rem;
  padding-right: 1.22rem;
}

.um_data-login-row {
  padding: 10px !important;
  margin-top: 10px !important;
}

.container2 {
  margin-left: 0px !important;
  transition: margin-left 0.2s ease-in !important;
  padding: 10px !important;
  box-sizing: border-box !important;
  background: #ffff !important;
}

.container1 {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

.container3 {
  justify-content: center !important;
  align-items: center !important;
  text-align: center !important;
}

.texto-label-login-U {
  font-size: 0.7rem !important;
  justify-content: center;
  align-items: center;
}

.center-texto {
  margin-top: 30px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.center-texto-SinTop {
  display: flex;
  justify-content: center;
  align-items: center;
}

.left-texto {
  display: flex;
  justify-content: left;
  align-items: left;
}

.leaflet-tooltip-own {
  position: absolute;
  padding: 4px;
  background-color: rgba(255, 255, 255, 0.4);
  border: 0px solid #000;
  color: #ffffff;
  white-space: nowrap;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  pointer-events: none;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4);
  color: rgba(21, 67, 139, 1) !important;
  font-size: 0.6rem !important;
}

.leaflet-tooltip-ownRojo {
  position: absolute;
  padding: 4px;
  background-color: rgba(255, 255, 255, 0.4);
  border: 0px solid #000;
  color: #ffffff;
  white-space: nowrap;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  pointer-events: none;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4);
  color: rgb(254, 0, 0) !important;
  font-size: 0.7rem !important;
  font-weight: bold;
}

.leaflet-tooltip-ownVerde {
  position: absolute;
  padding: 4px;
  background-color: rgba(255, 255, 255, 0.4);
  border: 0px solid #000;
  color: #ffffff;
  white-space: nowrap;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  pointer-events: none;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4);
  color: rgb(5, 176, 2) !important;
  font-size: 0.7rem !important;
  font-weight: bold;
}

.logo-empresa {
  width: 40%;
  background: #ffffff !important;
  border-radius: 5px !important;
  margin-top: 2px !important;
}

.logo-empresaT {
  width: 100%;
  background: #ffffff !important;
  border-radius: 5px !important;
  margin-top: 2px !important;
}

.logo-empresaR {
  text-align: center !important;
  justify-content: center !important;
  width: 50% !important;
  background: #ffffff !important;
  border-radius: 5px !important;
  margin-top: 10px !important;
}

.icon-Bpsystem {
  color: #e67e22 !important;
}

.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}

.centerPie {
  display: block;
  margin-left: auto;
  margin-right: auto;
  font-size: 1rem !important;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.centerT {
  margin-top: 0px !important;
  justify-content: center;
  align-items: center;
  display: flex;
  width: 60%;
}

.reloj1 {
  color: rgba(21, 67, 139, 1) !important;
  font-size: 1rem !important;
  text-align: center !important;
  justify-content: center !important;
}

.logo-empresa1 {
  text-align: center !important;
  justify-content: center !important;
  width: 100% !important;
  background: #ffffff !important;
  border-radius: 5px !important;
  margin-top: 10px !important;
}

.errorMsg {
  width: 100% !important;
  color: #f21e08 !important;
  padding: 1px 0;
  margin-top: 1%;
  font-size: 0.8rem;
  font-weight: bold;
}

//-------------------Dashboard-----------------------------------------------------------------------------------
$dark-gray: #9a9a9a !default;
$transparent-bg: transparent !default;
$font-paragraph: 16px !default;

.text-SOS,
.text-SOS:hover {
  color: #9368e9 !important;
}

.text-EME,
.text-EME:hover {
  color: #dc3545 !important;
}

.text-URG,
.text-URG:hover {
  color: #ffc107 !important;
}

.text-DEN,
.text-DEN:hover {
  color: #007bff !important;
}

.text-PLA,
.text-PLA:hover {
  color: #afb6c0 !important;
}

.stats {
  color: #a9a9a9;
  font-size: 0.7rem !important;
}

.stats0 {
  color: #ffffff;
  font-size: 0.6rem !important;
}

.stats1 {
  color: #3925fd;
  font-size: 2.3rem !important;
  font-weight: 800;
  text-align: center !important;
  justify-content: center !important;
}

.stats2 {
  color: #a9a9a9;
  font-size: 0.7rem !important;
  text-align: center !important;
  justify-content: center !important;
}

.card-category,
label {
  font-size: $font-size-base;
  font-weight: $font-weight-normal;
  color: $dark-gray;
  margin-bottom: 0px;

  i {
    font-size: $font-paragraph;
  }
}

.card-footer {
  padding-top: 0;
  background-color: $transparent-bg;
  line-height: 30px;
  border-top: none !important;
  font-size: 14px;

  .legend {
    padding: 5px 0;
  }

  hr {
    margin-top: 5px;
    margin-bottom: 5px;
  }
}

.card-stats {
  .card-body {
    padding: 15px 15px 0px;

    .numbers {
      font-size: 1.8rem;
      text-align: right;

      p {
        margin-bottom: 0;
      }
    }
  }
  .card-footer {
    padding: 0px 15px 10px 15px;
  }
  .icon-big {
    font-size: 3em;
    min-height: 64px;

    i {
      font-weight: 700;
      line-height: 59px;
    }
  }
}

.card-stats {
  .card-body {
    padding: 15px 15px 0px;

    .numbers {
      font-size: 1.8rem;
      text-align: right;

      p {
        margin-bottom: 0;
      }
    }
  }
  .card-footer {
    padding: 0px 15px 10px 15px;
  }

  .icon-big {
    font-size: 3em;
    min-height: 64px;

    i {
      font-weight: 700;
      line-height: 59px;
    }
  }
}

.img-xxl {
  width: 60px !important;
  height: 60px !important;
  padding: 0px;
}

//-----------------------------Misdenuncias------------------------------------------------------
.leaflet-containerCasosAct {
  width: 100%;
  height: 100%;
}

.img-DenunciaDetalleDespacho {
  padding: 0.1rem;
  height: 60px;
  max-width: 100%;
  border-radius: 5%;
  cursor: pointer;
  box-shadow: 3px 5px 7px rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.img-DenunciaDetalle {
  padding: 0.3rem;
  height: 80px;
  max-width: 100%;
  border-radius: 5%;
  cursor: pointer;
  box-shadow: 3px 5px 7px rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.img-DenunciaDetalle0 {
  padding: 0.3rem;
  height: 120px;
  border-radius: 5%;
  box-shadow: 3px 5px 7px rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.img-DenunciaDetalle1 {
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Row-leaflet-containerDen {
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid #eeeeee !important;
  background-color: #fff !important;
  border-radius: 5px !important;
  box-shadow: 0 2px 3px rgba(212, 207, 207, 20%),
    0 3px 3px rgba(212, 207, 207, 20%) !important;
  margin-bottom: 10px !important;
  height: 87vh !important;
}

.Row-leaflet-containerInspeccion {
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid #eeeeee !important;
  background-color: #fff !important;
  border-radius: 5px !important;
  box-shadow: 0 2px 3px rgba(212, 207, 207, 20%),
    0 3px 3px rgba(212, 207, 207, 20%) !important;
  margin-bottom: 10px !important;
  height: 66vh !important;
}

.Row-leaflet-containerDenAdm {
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid #eeeeee !important;
  background-color: #fff !important;
  border-radius: 5px !important;
  box-shadow: 0 2px 3px rgba(212, 207, 207, 20%),
    0 3px 3px rgba(212, 207, 207, 20%) !important;
  margin-bottom: 10px !important;
  height: 30vh !important;
}

.Row-leaflet-containerDenAnt {
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid #eeeeee !important;
  background-color: #fff !important;
  border-radius: 5px !important;
  box-shadow: 0 2px 3px rgba(212, 207, 207, 20%),
    0 3px 3px rgba(212, 207, 207, 20%) !important;
  margin-bottom: 10px !important;
  height: 40vh;
}

.Row-leaflet-containerDenGrab {
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid #eeeeee !important;
  background-color: #fff !important;
  border-radius: 5px !important;
  box-shadow: 0 2px 3px rgba(212, 207, 207, 20%),
    0 3px 3px rgba(212, 207, 207, 20%) !important;
  margin-bottom: 10px !important;
  height: 60vh !important;
}

.request-popup {
  top: -340px !important;
}

.img-Denuncia {
  height: 100px;
  max-width: 100%;

  border-radius: 5%;
  cursor: pointer;
  box-shadow: 3px 5px 7px rgba(0, 0, 0, 0.5);
}

.img-Denuncia-despacho {
  height: 50px;
  max-width: 100%;

  border-radius: 5%;
  cursor: pointer;
  box-shadow: 3px 5px 7px rgba(0, 0, 0, 0.5);
}

.img-DenunciaDetalle1 {
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.img-DenunciaDetalle {
  padding: 0.3rem;
  height: 80px;
  max-width: 100%;
  border-radius: 5%;
  cursor: pointer;
  box-shadow: 3px 5px 7px rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.img-DenunciaDetalle0 {
  padding: 0.3rem;
  height: 120px;
  border-radius: 5%;
  box-shadow: 3px 5px 7px rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.textoCuadroDespacho {
  font-size: 0.7rem !important;
}

.textoCuadroPosicion {
  font-size: 1rem !important;
}

.textocentradoAntecedentes1 {
  color: $text-color3 !important;
  font-size: 0.8rem !important;
}
.textocentradoAntecedentes2 {
  color: $base-color !important;
  font-size: 0.8rem !important;
}

.textocentradoAntecedentes1Titulo {
  color: $text-color3 !important;
  font-size: 1rem !important;
}

.textocentradoAntecedentes1TituloTurno {
  color: $blue-color1 !important;
  font-size: 1.2rem !important;
}

.textocentradoAntecedentes2Titulo {
  color: $base-color !important;
  font-size: 1rem !important;
}

.text-primary,
.text-primary:hover {
  color: #1d62f0 !important;
}

.card-stats {
  .card-body {
    padding: 15px 15px 0px;

    .numbers {
      font-size: 1.8rem;
      text-align: right;

      p {
        margin-bottom: 0;
      }
    }
  }
  .card-footer {
    padding: 0px 15px 10px 15px;
  }
  .icon-big {
    font-size: 3em;
    min-height: 64px;

    i {
      font-weight: 700;
      line-height: 59px;
    }
  }
}

.img-pointer {
  cursor: pointer;
}

.inputfile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.fondo_antecedentes {
  box-shadow: 3px 5px 7px rgba(0, 0, 0, 0.5);
  border: 3px solid rgb(204, 204, 204);
}

.img-DenunciaAntecedente {
  height: 4rem;
  width: 4rem;
  min-height: 4rem;
  min-width: 4rem;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  border-radius: 50%;
  padding: 0.1rem;
  cursor: pointer;
  box-shadow: 3px 5px 7px rgba(0, 0, 0, 0.5);
  align-items: center;
}

.custom-checkbox-reverse {
  padding-left: 0;
  padding-right: 1.5rem;
}

.custom-checkbox-reverse .custom-control-label::before,
.custom-checkbox-reverse .custom-control-label::after {
  left: auto;
  right: -1.5rem;
}

.offcanvas-start {
  transform: translateX(-100%) !important;
}

.offcanvas-end {
  transform: translateX(100%) !important;
}

.offcanvas.show {
  -webkit-transform: none !important;
  transform: none !important;
}
//-----------------------------Misdenuncias------------------------------------------------------

//-----------------------Registro User ----------------------------------------------------------

.flag-img1 {
  width: 55px;
  height: 25px;
  position: flex;
  border-radius: 5px;
  border: 1px solid;
}

.icon-login {
  font-size: 35px !important;
  padding: 0px 0 0 5px !important;
  position: flex !important;
  border-radius: 5px !important;
}

.form-control.input-error {
  border: 1px solid #f21e08 !important;
}

.text-danger,
.text-danger:hover {
  color: $blue-color1 !important;
}

.um_data {
  border: 1px solid #eeeeee !important;
  border-radius: 4px;
  box-shadow: 0 2px 3px rgba(212, 207, 207, 20%),
    0 3px 3px rgba(212, 207, 207, 20%) !important;
  padding: 5px 10px 5px;
  margin-bottom: 10px;
}

.leaflet-container {
  border-radius: 5px;
  width: 100%;
  height: 160px;
  border: 1px solid #c0c0c0 !important;
}

.col-texto-registro {
  display: flex !important;
  font-size: 1.5rem !important;
  justify-content: center !important;
}

.col-titulo-registro {
  background-color: #fff !important;
  border-radius: 5px !important;
  text-align: center;
  padding: 10px !important;
}

.steps {
  display: flex;
  justify-content: center;

  margin-top: 0rem;

  .step {
    margin: 0.5rem 1.5rem;
    display: flex;
    color: #ccc !important;

    a {
      color: $base-color;
    }

    div:first-of-type {
      border: 1px solid #ccc !important;
      padding: 5px;
      border-radius: 50%;
      margin-right: 0.5rem;
      width: 2.5rem;
      height: 2rem;
      text-align: center;
      line-height: 100%;
      padding-top: 2%;
      font-size: 1.5rem;
    }

    :last-child {
      margin-top: 3%;
    }

    &.active {
      div:first-of-type {
        background-color: $blue-color;
        border: 1px solid transparent;
        color: #fff;
      }

      div:last-of-type {
        color: $blue-color;
      }
    }
  }
}

.col-titulo-loginR {
  border: 1px solid #eeeeee !important;
  background-color: #fff !important;
  border-radius: 5px !important;
  box-shadow: 0 2px 3px #d4cfcf, 0 3px 3px #d4cfcf !important;
  margin-bottom: 10px !important;
  padding: 10px !important;
  margin-top: 3%;
  margin-right: auto;
  margin-left: auto;
  height: 100%;
  width: 45vw;
  min-height: 2.5rem;
  min-width: 2.5rem;
}

.react-tel-input {
  font-size: 0.9rem !important;
}

.react-tel-input .form-control {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  height: 3.5rem !important;
  position: relative;
  letter-spacing: 0.01rem;
  border-radius: 0 !important;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  width: 100% !important;
  font-size: 0.9rem !important;
}

//-----------------------Registro User -------------------------------------------------------------

//-----------------------MapaCasos-------------------------------------------------------------------
.col-titulo-denuncia {
  border: 1px solid #eeeeee !important;
  background-color: #fff !important;
  border-radius: 5px !important;
  box-shadow: 0 2px 3px rgba(212, 207, 207, 20%),
    0 3px 3px rgba(212, 207, 207, 20%) !important;
  margin-bottom: 5px !important;
  padding: 5px !important;
}

.col-texto-denuncia {
  display: flex !important;
  font-size: 1.5rem !important;
  justify-content: center !important;
}

.logo-titulos {
  width: 50px;
  height: 50px;
  alt: "";
}

.logo-titulos-inspeccion {
  width: 30px;
  height: 30px;
  alt: "";
}
.Row-leaflet-containerCasosAct {
  min-height: 31vh;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid #eeeeee !important;
  background-color: #fff !important;
  border-radius: 5px !important;
  box-shadow: 0 2px 3px rgba(212, 207, 207, 20%),
    0 3px 3px rgba(212, 207, 207, 20%) !important;
  margin-bottom: 10px !important;
}

.Row-leaflet-containerTurnos {
  min-height: 31vh;
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid #eeeeee !important;
  background-color: #fff !important;
  border-radius: 5px !important;
  box-shadow: 0 2px 3px rgba(212, 207, 207, 20%),
    0 3px 3px rgba(212, 207, 207, 20%) !important;
  margin-bottom: 10px !important;
}

.img-DenunciaAntecedente2 {
  height: 3rem;
  width: 3rem;
  min-height: 3rem;
  min-width: 3rem;
  color: #e67e22 !important;
  font-size: 0.6rem !important;
  font-weight: bold !important;
  border-radius: 50%;
  padding: 0.1rem;
  cursor: pointer;
  box-shadow: 3px 5px 7px rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.circleTip {
  box-shadow: none;
  border-radius: 6px;
  border: 1px solid #007bff;
  padding: 5px;
  font-size: 10px;
  font-weight: 600;
  text-align: center;
}

.circleTipclassic {
  background: #fdfde1;
  border: 1px solid #ffad33;
  font-weight: 800;
}

.circleTipclassic {
  background: #fdfde1;
  border: 1px solid #ffad33;
  font-weight: 600;
  white-space: pre-wrap;
  text-align: center;
}

.classic {
  background: #ffffaa;
  border: 1px solid #ffad33;
}
.critical {
  background: #ffccaa;
  border: 1px solid #ff3334;
}
.help {
  background: #9fdaee;
  border: 1px solid #2bb0d7;
}
.info {
  background: #9fdaee;
  border: 1px solid #2bb0d7;
}
.warning {
  background: #ffffaa;
  border: 1px solid #ffad33;
}

//-----------------------MapaCasos-------------------------------------------------------------------

//-----------------------Perfil-------------------------------------------------------------------

.card-user {
  .card-image {
    height: 110px;
  }
  .card-image-plain {
    height: 0;
    margin-top: 110px;
  }
  .author {
    text-align: center;
    text-transform: none;
    margin-top: -70px;
  }
  .avatar {
    width: 124px;
    height: 124px;
    border: 5px solid #ffffff;
    position: relative;
    margin-bottom: 15px;

    &.border-gray {
      border-color: #eeeeee;
    }
  }
  .title {
    line-height: 24px;
    font-size: 12px;
  }
  .card-body {
    min-height: 240px;
  }
}

.card-user,
.card-price {
  .card-footer {
    padding: 5px 15px 10px;
  }
  hr {
    margin: 5px 15px;
  }
}

.img-avatar {
  width: 40px;
  height: 40px;

  cursor: pointer;
}

.img-avatar-Despacho {
  width: 40px;
  height: 30px;
  cursor: pointer;
}

//-----------------------Perfil-------------------------------------------------------------------

.mymarker svg path {
  fill: black;
}
.mymarker {
  transition: transform 0.5s linear;
}
.indicator {
  font-family: "Aldrich", "Helvetica Neue", Arial, sans-serif;
  background-color: white;
  border: 1px solid dodgerblue;
  border-radius: 6px;
  color: dodgerblue;
  font-size: 8px;
  font-weight: 400;
  padding: 2px 4px;
  position: relative;
  bottom: 25px;
  white-space: nowrap;
  transition: transform 0.5s linear;
  top: 25px;
  left: -20px;
}

.indicator1 {
  font-family: "Aldrich", "Helvetica Neue", Arial, sans-serif;
  background-color: white;
  border: 1px solid #e67e22;
  border-radius: 6px;
  color: #e67e22;
  font-size: 8px;
  font-weight: 400;
  padding: 2px 4px;
  position: relative;
  bottom: 25px;

  white-space: nowrap;
  transition: transform 0.5s linear;
  top: 25px;
  left: -20px;
}

.indicatorD {
  font-family: "Aldrich", "Helvetica Neue", Arial, sans-serif;
  background-color: white;
  border: 1px solid #22ac11;
  border-radius: 6px;
  color: #22ac11;
  font-size: 8px;
  font-weight: 400;
  padding: 2px 4px;
  position: relative;
  bottom: 25px;
  white-space: nowrap;
  transition: transform 0.5s linear;
  top: 25px;
  left: -20px;
}

.indicatorND {
  font-family: "Aldrich", "Helvetica Neue", Arial, sans-serif;
  background-color: white;
  border: 1px solid #dc3545;
  border-radius: 6px;
  color: #dc3545;
  font-size: 8px;
  font-weight: 400;
  padding: 2px 4px;
  position: relative;
  bottom: 25px;
  white-space: nowrap;
  transition: transform 0.5s linear;
  top: 25px;
  left: -20px;
}

.indicatorY {
  font-family: "Aldrich", "Helvetica Neue", Arial, sans-serif;
  background-color: white;
  border: 1px solid dodgerblue;
  border-radius: 6px;
  color: dodgerblue;
  font-size: 8px;
  font-weight: 400;
  padding: 2px 4px;
  position: relative;
  bottom: 25px;
  white-space: nowrap;
  transition: transform 0.5s linear;
  top: 5px;
  left: -20px;
}

.StyleSelect {
  font-size: 1.8rem !important;
  resize: "none" !important;
}

.StyleSelectPrioridad {
  font-size: 1rem !important;
  resize: "none" !important;
}

.StyleSelectFechas {
  font-size: 1.5rem !important;
  resize: "none" !important;
}

.StyleSelectFechasJPL {
  font-size: 1.3rem !important;
  resize: "none" !important;
}

.datetimeControl p {
  margin: 0;
  font-size: 16px;
}

.audioControl {
  width: 550px !important;
  height: 50px !important;
}

//-------------------Dashboard-----------------------------------------------------------------------------------

@media only screen and (min-width: 427px) and (max-width: 768px) {
  .reloj1 {
    color: rgba(21, 67, 139, 1) !important;
    font-size: 0.6rem !important;
    text-align: center !important;
    justify-content: center !important;
  }

  .stats1 {
    color: #007bff;
    font-size: 1.5rem !important;
    font-weight: 800;
    text-align: center !important;
    justify-content: center !important;
  }

  .centerPie {
    display: block;
    margin-left: auto;
    margin-right: auto;
    font-size: 0.8rem !important;
  }

  .col-titulo-login {
    border: 1px solid #eeeeee !important;
    background-color: #fff !important;
    border-radius: 5px !important;
    box-shadow: 0 2px 3px #d4cfcf, 0 3px 3px #d4cfcf !important;
    margin-bottom: 10px !important;
    padding: 10px !important;
    margin-top: 10%;
    margin-right: auto;
    margin-left: auto;
    height: 100%;
    width: 90vw;
    min-height: 2.5rem;
    min-width: 2.5rem;
  }

  .btn {
    margin-top: 20px !important;
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  .CHDivider {
    padding: 0px !important;
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }

  .center-texto {
    margin-top: 20px !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .left-texto {
    justify-content: center;
    align-items: center;
  }

  .logo-empresa {
    width: 45%;
    background: #ffffff !important;
    border-radius: 5px !important;
    margin-top: 0px !important;
  }

  .logo-empresa1 {
    text-align: center !important;
    justify-content: center !important;
    width: 100% !important;
    background: #ffffff !important;
    border-radius: 5px !important;
    margin-top: 0px !important;
  }
}
//-------------Login---------------------------------------------------------------------------

@media screen and (max-width: 426px) {

  .stats1 {
    color: #007bff;
    font-size: 1.5rem !important;
    font-weight: 800;
    text-align: center !important;
    justify-content: center !important;
  }

  /*Legend specific*/
  .legend {
    padding: 3px 4px;
    font: 10px Arial, Helvetica, sans-serif;
    background: white;
    background: rgba(255, 255, 255, 0.8);
    /*box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);*/
    /*border-radius: 5px;*/
    line-height: 12px;
    color: #555;
  }
  .legend h4 {
    text-align: center;
    font-size: 10px;
    margin: 1px 6px 4px;
    color: #777;
  }

  .legend span {
    position: relative;
    bottom: 3px;
  }

  .legend i {
    width: 10px;
    height: 10px;
    float: left;
    margin: 0 3px 0 0;
    opacity: 0.7;
  }

  .legend i.icon {
    background-size: 10px;
    background-color: rgba(255, 255, 255, 1);
  }

  .textoOption {
    font-size: 0.7rem !important;
  }

  .col-texto-denuncia {
    display: flex !important;
    font-size: 1rem !important;
    justify-content: center !important;
  }

  .col-titulo-login {
    border: 1px solid #eeeeee !important;
    background-color: #fff !important;
    border-radius: 5px !important;
    box-shadow: 0 2px 3px #d4cfcf, 0 3px 3px #d4cfcf !important;
    margin-bottom: 10px !important;
    padding: 10px !important;
    margin-top: 3%;
    margin-right: auto;
    margin-left: auto;
    height: 100%;
    width: 90vw;
    min-height: 2.5rem;
    min-width: 2.5rem;
  }

  .centerT {
    margin-top: -15px !important;
    display: flex;
    align-items: center;
    width: 35%;
  }

  .reloj1 {
    color: rgba(21, 67, 139, 1) !important;
    font-size: 0.5rem !important;
    text-align: center !important;
    justify-content: center !important;
  }

  .centerPie {
    display: block;
    margin-left: auto;
    margin-right: auto;
    font-size: 0.6rem !important;
  }

  .steps {
    display: flex;
    justify-content: center;

    margin-top: 0rem;

    .step {
      margin: 0.5rem 1rem;
      display: flex;
      color: #ccc !important;

      a {
        color: $base-color;
      }

      div:first-of-type {
        border: 1px solid #ccc !important;
        padding: 5px;
        border-radius: 50%;
        margin-right: 0.5rem;
        width: 1.5rem;
        height: 1.5rem;
        text-align: center;
        line-height: 100%;
        padding-top: 2%;
        font-size: 1rem;
      }

      :last-child {
        margin-top: 2%;
      }

      &.active {
        div:first-of-type {
          background-color: $blue-color;
          border: 1px solid transparent;
          color: #fff;
        }

        div:last-of-type {
          color: $blue-color;
        }
      }
    }
  }

  .StyleSelect {
    font-size: 1.2rem !important;
    resize: "none" !important;
  }

  .StyleSelectFechas {
    font-size: 1rem !important;
    resize: "none" !important;
  }

  .StyleSelectFechasJPL {
    font-size: 1rem !important;
    resize: "none" !important;
  }

  .audioControl {
    width: 250px !important;
    height: 50px !important;
  }

  .logo-empresa1 {
    text-align: center !important;
    justify-content: center !important;
    width: 100% !important;
    background: #ffffff !important;
    border-radius: 5px !important;
    margin-top: 5px !important;
  }

  .logo-empresaT {
    width: 100% !important;
    background: #ffffff !important;
    border-radius: 5px !important;
    margin-top: 2px !important;
  }

  .Row-leaflet-containerDen {
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid #eeeeee !important;
    background-color: #fff !important;
    border-radius: 5px !important;
    box-shadow: 0 2px 3px rgba(212, 207, 207, 20%),
      0 3px 3px rgba(212, 207, 207, 20%) !important;
    margin-bottom: 10px !important;
    height: 40vh !important;
  }

  .Row-leaflet-containerInspeccion {
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid #eeeeee !important;
    background-color: #fff !important;
    border-radius: 5px !important;
    box-shadow: 0 2px 3px rgba(212, 207, 207, 20%),
      0 3px 3px rgba(212, 207, 207, 20%) !important;
    margin-bottom: 10px !important;
    height: 40vh !important;
  }

  .textoTurno {
    font-size: 0.8rem !important;
  }
}
